import { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { GridWrap } from '@ui/components/Grid/Grid';
import Tile from '@ui/components/Tile/Tile';
import { mqMin } from '@ui/styles/base';
import { legacySpacings } from '@ui-v2/theme/layout';
import FrontPageSection from '../FrontPage/FrontPageContent';
import SEO, { Hreflang } from '../SEO/SEO';
import FrontPageCoverContainer from './FrontPageCover/FrontPageCoverContainer';
import useGetFrontPageData from './hooks/useGetFrontPageData';

const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
  overflow-x: hidden;
`;

const StyledGridWrap = styled(GridWrap)(
  ({ theme: { spacings } }) => css`
    display: flex;
    min-height: 350px;
    flex-direction: column;
    padding: ${spacings['24']}px 0 ${legacySpacings.ExtraExtraLarge}px 0;
    gap: ${legacySpacings.ExtraExtraLarge}px;
  `,
);

const FrontPageSectionWrapper = styled.div<{
  backgroundColor?: Maybe<string>;
  bottomSlantedToRightVariant?: Maybe<boolean>;
}>(({ backgroundColor, bottomSlantedToRightVariant }) => [
  backgroundColor &&
    css`
      background-color: ${backgroundColor};
    `,
  bottomSlantedToRightVariant &&
    css`
      position: relative;
      width: min(100vw, 1440px);
      padding-top: 40px;
      padding-bottom: 60px;
      margin-left: calc((100% - min(100vw, 1440px)) / 2);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 97%);

      > * {
        max-width: 1312px;
        margin: auto;
      }

      ${mqMin.ExtraSmall} {
        padding-bottom: 100px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 94%);
      }

      ${mqMin.Large} {
        padding-top: 80px;
        padding-bottom: 200px;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 84%);
      }
    `,
]);

const FrontPageContainer = () => {
  const { content: frontpageContent } = useGetFrontPageData();
  const seo = frontpageContent?.seo;

  const content = frontpageContent?.content;
  const hreflangs = (seo?._allCanonicalUrlLocales
    ?.filter((hreflang) => hreflang.locale && hreflang.value)
    .map((hreflang) => ({
      locale: hreflang.locale,
      value: hreflang.value,
    })) || []) as Hreflang[];

  return (
    <>
      <SEO
        canonicalUrl={seo?.canonicalUrl}
        description={seo?.description?.value}
        hreflangs={hreflangs}
        isIndexed
        openGraphImage={seo?.openGraphImage}
        title={seo?.title?.value}
      />
      <FrontPageCoverContainer />
      {content && content.length > 0 && (
        <ContentContainer>
          <StyledGridWrap>
            {content.map(
              ({
                backgroundColor,
                backgroundVariant,
                id,
                isTile,
                sectionData,
              }) => {
                const bottomSlantedToRightVariant =
                  backgroundVariant === 'bottom-slanted-to-right';

                const SectionContainer = isTile
                  ? (props: { children: ReactNode; key: string }) => (
                      <Tile {...props} />
                    )
                  : (props: { key: string }) => <div {...props} />;

                return (
                  <SectionContainer key={id}>
                    <FrontPageSectionWrapper
                      backgroundColor={backgroundColor}
                      bottomSlantedToRightVariant={bottomSlantedToRightVariant}
                    >
                      <FrontPageSection
                        content={sectionData}
                        isSectionTile={isTile}
                      />
                    </FrontPageSectionWrapper>
                  </SectionContainer>
                );
              },
            )}
          </StyledGridWrap>
        </ContentContainer>
      )}
    </>
  );
};

export default FrontPageContainer;
